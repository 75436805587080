const linkedin = "kavish-prasanjana"
const instagram = "__kavish__"
const facebook = "kprasanjana"

const links = [
  {
    label: "Linkedin",
    url: `https://www.linkedin.com/in/${linkedin}`,
  },
  {
    label: "Instagram",
    url: `https://www.instagram.com/${instagram}`,
  },
  {
    label: "EmailOutline",
    url: `mailto:kavish@gmail.com`,
  },
]

export default links
