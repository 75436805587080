import { Github } from "@styled-icons/boxicons-logos/Github"
import { Twitter } from "@styled-icons/boxicons-logos/Twitter"
import { Linkedin } from "@styled-icons/boxicons-logos/Linkedin"
import { Facebook } from "@styled-icons/boxicons-logos/Facebook"
import { Instagram } from "@styled-icons/boxicons-logos/Instagram"
import { EmailOutline } from "@styled-icons/evaicons-outline/EmailOutline"

const Icons = {
  Github,
  Twitter,
  Linkedin,
  Facebook,
  Instagram,
  EmailOutline,
}

export default Icons
