const links = [
  {
    label: 'Home',
    url: '/'
  },
  {
    label: 'About Me',
    url: '/about/'
  }
]

export default links
